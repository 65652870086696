import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { ToastContainer, toast } from "react-toastify";
import { Button, Space, Toast, Size, ToastCloseButton, IconGlobalMessage, AlertLink } from '@hse-design/react';
import { colorSystemError, colorSystemSuccess, colorSystemWarning } from "@hse-design/tokens";
import { FigmaEmbed, ComponentLinks } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "toast"
    }}>{`Toast`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Toast } from '@hse-design/react'
import { ToastContainer, toast } from "react-toastify";
`}</code></pre>
    <ComponentLinks figma={"https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=8267%3A54462"} storybook={"/?path=/story/toast-plugin--headline"} vue={"/#/toast"} vueStorybook={"/?path=/story/toast-plugin--headline"} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Toast  —  это всплывающее в углу экрана сообщение.
Он служит механизмом обратной связи и подтверждения совершения действия.
Также может являться средством уведомления пользователя.`}</p>
    <p>{`Есть 4 варианта Toast:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`info`}</inlineCode>{` – для информационных сообщений`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`success`}</inlineCode>{` – для сообщений об успешности операций`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`error`}</inlineCode>{` – для ошибок`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`warning`}</inlineCode>{` – для предупреждений`}</li>
    </ul>
    <p>{`Компонент Toast не включает в себя логику позиционирования.
Для того, чтобы динамически отображать тосты в углу экрана,
используйте библиотеку `}<a parentName="p" {...{
        "href": "https://github.com/fkhadra/react-toastify"
      }}><inlineCode parentName="a">{`react-toastify`}</inlineCode></a>{`.
Подключение и использование библиотеки более подробно описано ниже.`}</p>
    <p>{`Пользователи могут закрыть Toast, нажав кнопку «Закрыть».
По-умолчанию Toast автоматически закроется через пять секунд.
При наведении курсора на всплывающее сообщение оно будет оставаться до тех пор, пока курсор не будет перемещен за его пределы.`}</p>
    <h2 {...{
      "id": "поведение"
    }}>{`Поведение`}</h2>
    <FigmaEmbed node='?node-id=9786%3A2073' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node='?node-id=9786%3A2096' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node='?node-id=9786%3A2178' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "подключение-библиотеки"
    }}>{`Подключение библиотеки`}</h3>
    <p>{`Установите библиотеку:`}</p>
    <pre><code parentName="pre" {...{}}>{`yarn add react-toastify
# или
npm i react-toastify
`}</code></pre>
    <p>{`В основном компоненте приложения (например, `}<inlineCode parentName="p">{`App`}</inlineCode>{`) добавьте `}<inlineCode parentName="p">{`ToastContainer`}</inlineCode>{` с рекомендуемыми настройками по-умолчанию.
Вы можете передать дополнительные параметры для настройки `}<inlineCode parentName="p">{`ToastContainer`}</inlineCode>{` под требования конкретного проекта
в соответствии с `}<a parentName="p" {...{
        "href": "https://fkhadra.github.io/react-toastify/introduction/"
      }}>{`документацией `}<inlineCode parentName="a">{`react-toastify`}</inlineCode></a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React from 'react';

import { ToastContainer } from 'react-toastify';
import { ToastCloseButton } from "@hse-design/react";

function App() {
    return (
        <>
            <ToastContainer
                closeButton={ToastCloseButton}
                autoClose={5000}
                hideProgressBar={true}
                closeOnClick={false}
            />
        </>
    );
}
`}</code></pre>
    <h3 {...{
      "id": "использование-библиотеки"
    }}>{`Использование библиотеки`}</h3>
    <p>{`В необходимом компоненте вызовите метод `}<inlineCode parentName="p">{`toast`}</inlineCode>{` библиотеки `}<inlineCode parentName="p">{`react-toastify`}</inlineCode>{`.`}</p>
    <p>{`Первым аргументом необходимо передать инстанс компонента `}<inlineCode parentName="p">{`Toast`}</inlineCode>{` с нужными параметрами.`}</p>
    <p>{`Вы можете использовать второй аргумент функции `}<inlineCode parentName="p">{`toast`}</inlineCode>{` для передачи дополнительных параметров в соответствии
с `}<a parentName="p" {...{
        "href": "https://fkhadra.github.io/react-toastify/introduction/"
      }}>{`документацией библиотеки`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { toast } from 'react-toastify';
import { Button, Toast } from '@hse-design/react';

const notify = () => toast(<Toast title={'Some Title'} description={'Some description'} variant={Toast.Variant.info} />);

function SomeComponent() {
    return (
        <Button onClick={notify}>
            Notify
        </Button>
    )
}
`}</code></pre>
    <Playground __position={4} __code={'<ToastContainer\n  autoClose={5000}\n  hideProgressBar={true}\n  closeOnClick={false}\n  closeButton={ToastCloseButton}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ToastContainer,
      toast,
      Button,
      Space,
      Toast,
      Size,
      ToastCloseButton,
      IconGlobalMessage,
      AlertLink,
      colorSystemError,
      colorSystemSuccess,
      colorSystemWarning,
      FigmaEmbed,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <ToastContainer autoClose={5000} hideProgressBar={true} closeOnClick={false} closeButton={ToastCloseButton} mdxType="ToastContainer" />
    </Playground>
    <h3 {...{
      "id": "варианты-toast"
    }}>{`Варианты Toast`}</h3>
    <p>{`Всего есть 4 варианта: `}<inlineCode parentName="p">{`info`}</inlineCode>{`, `}<inlineCode parentName="p">{`success`}</inlineCode>{`, `}<inlineCode parentName="p">{`error`}</inlineCode>{`, `}<inlineCode parentName="p">{`warning`}</inlineCode>{`. Передайте в свойство `}<inlineCode parentName="p">{`variant`}</inlineCode>{` нужный.`}</p>
    <p>{`Передайте необходимый заголовок в свойство `}<inlineCode parentName="p">{`title`}</inlineCode></p>
    <Playground __position={5} __code={'() => {\n  const colorVariantMap = {\n    [Toast.Variant.info]: \'\',\n    [Toast.Variant.success]: colorSystemSuccess,\n    [Toast.Variant.error]: colorSystemError,\n    [Toast.Variant.warning]: colorSystemWarning,\n  }\n  const notify = (toastProps = {}, toastifyOptions = {}) => () =>\n    toast(<Toast {...toastProps} />, {\n      autoClose: 5000,\n      hideProgressBar: true,\n      closeOnClick: false,\n      ...toastifyOptions,\n    })\n  return (\n    <>\n      <div>\n        {Object.keys(Toast.Variant).map(variant => (\n          <div key={variant}>\n            <Button\n              color={colorVariantMap[variant]}\n              onClick={notify({ title: `${variant} toast`, variant })}\n            >\n              Open {variant} toast\n            </Button>\n            <Space size={Size.small_2x} vertical />\n          </div>\n        ))}\n      </div>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ToastContainer,
      toast,
      Button,
      Space,
      Toast,
      Size,
      ToastCloseButton,
      IconGlobalMessage,
      AlertLink,
      colorSystemError,
      colorSystemSuccess,
      colorSystemWarning,
      FigmaEmbed,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      {() => {
        const colorVariantMap = {
          [Toast.Variant.info]: '',
          [Toast.Variant.success]: colorSystemSuccess,
          [Toast.Variant.error]: colorSystemError,
          [Toast.Variant.warning]: colorSystemWarning
        };

        const notify = (toastProps = {}, toastifyOptions = {}) => () => toast(<Toast {...toastProps} mdxType="Toast" />, {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          ...toastifyOptions
        });

        return <>
            <div>
                {Object.keys(Toast.Variant).map(variant => <div key={variant}>
                        <Button color={colorVariantMap[variant]} onClick={notify({
                title: `${variant} toast`,
                variant
              })} mdxType="Button">
                            Open {variant} toast
                        </Button>
                        <Space size={Size.small_2x} vertical mdxType="Space" />
                    </div>)}
            </div>
        </>;
      }}
    </Playground>
    <h3 {...{
      "id": "toast-с-описанием"
    }}>{`Toast с описанием`}</h3>
    <p>{`Используйте свойства `}<inlineCode parentName="p">{`title`}</inlineCode>{` и `}<inlineCode parentName="p">{`description`}</inlineCode>{` для передачи заголовка и описания.`}</p>
    <p>{`Также вы можете передать необходимую иконку в свойство `}<inlineCode parentName="p">{`icon`}</inlineCode>{`.`}</p>
    <Playground __position={6} __code={'() => {\n  const colorVariantMap = {\n    [Toast.Variant.info]: \'\',\n    [Toast.Variant.success]: colorSystemSuccess,\n    [Toast.Variant.error]: colorSystemError,\n    [Toast.Variant.warning]: colorSystemWarning,\n  }\n  const notify = (toastProps = {}, toastifyOptions = {}) => () =>\n    toast(<Toast {...toastProps} />, {\n      autoClose: 5000,\n      hideProgressBar: true,\n      closeOnClick: false,\n      ...toastifyOptions,\n    })\n  return (\n    <>\n      <div>\n        {Object.keys(Toast.Variant).map(variant => (\n          <div key={variant}>\n            <Button\n              color={colorVariantMap[variant]}\n              onClick={notify({\n                title: `${variant} toast`,\n                description: \'Some description\',\n                variant,\n                icon: IconGlobalMessage,\n              })}\n            >\n              Open {variant} toast\n            </Button>\n            <Space size={Size.small_2x} vertical />\n          </div>\n        ))}\n      </div>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ToastContainer,
      toast,
      Button,
      Space,
      Toast,
      Size,
      ToastCloseButton,
      IconGlobalMessage,
      AlertLink,
      colorSystemError,
      colorSystemSuccess,
      colorSystemWarning,
      FigmaEmbed,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    {() => {
        const colorVariantMap = {
          [Toast.Variant.info]: '',
          [Toast.Variant.success]: colorSystemSuccess,
          [Toast.Variant.error]: colorSystemError,
          [Toast.Variant.warning]: colorSystemWarning
        };

        const notify = (toastProps = {}, toastifyOptions = {}) => () => toast(<Toast {...toastProps} mdxType="Toast" />, {
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          ...toastifyOptions
        });

        return <>
                <div>
                    {Object.keys(Toast.Variant).map(variant => <div key={variant}>
                            <Button color={colorVariantMap[variant]} onClick={notify({
                title: `${variant} toast`,
                description: 'Some description',
                variant,
                icon: IconGlobalMessage
              })} mdxType="Button">
                                Open {variant} toast
                            </Button>
                            <Space size={Size.small_2x} vertical mdxType="Space" />
                        </div>)}
                </div>
            </>;
      }}
    </Playground>
    <h3 {...{
      "id": "toast-с-действиями"
    }}>{`Toast с действиями`}</h3>
    <p>{`Используйте слот `}<inlineCode parentName="p">{`actions`}</inlineCode>{` для того, чтобы добавить кнопки действий.
Для кнопок действий рекомендуется использовать компонент `}<inlineCode parentName="p">{`AlertLink`}</inlineCode>{`.`}</p>
    <p>{`В слот `}<inlineCode parentName="p">{`actions`}</inlineCode>{` можно передать рендер-функцию, принимающую объект пропов,
содержащий функцию `}<inlineCode parentName="p">{`close`}</inlineCode>{`, которую можно вызвать, чтобы закрыть тост
в ответ на действие пользователя.`}</p>
    <p>{`При наличии действий передайте в функцию `}<inlineCode parentName="p">{`toast`}</inlineCode>{` параметр `}<inlineCode parentName="p">{`autoClose: false`}</inlineCode>{`,
чтобы тост не закрылся до того, как пользователь выполнит необходимое действие.`}</p>
    <Playground __position={7} __code={'() => {\n  const colorVariantMap = {\n    [Toast.Variant.info]: \'\',\n    [Toast.Variant.success]: colorSystemSuccess,\n    [Toast.Variant.error]: colorSystemError,\n    [Toast.Variant.warning]: colorSystemWarning,\n  }\n  const notify = (toastProps = {}, toastifyOptions = {}) => () =>\n    toast(<Toast {...toastProps} />, {\n      autoClose: false,\n      hideProgressBar: true,\n      closeOnClick: false,\n      ...toastifyOptions,\n    })\n  return (\n    <>\n      <div>\n        {Object.keys(Toast.Variant).map(variant => (\n          <div key={variant}>\n            <Button\n              color={colorVariantMap[variant]}\n              onClick={notify({\n                title: `${variant} toast`,\n                description: \'Some description\',\n                variant,\n                icon: IconGlobalMessage,\n                actions: ({ close }) => (\n                  <AlertLink onClick={close}>Принять</AlertLink>\n                ),\n              })}\n            >\n              Open {variant} toast\n            </Button>\n            <Space size={Size.small_2x} vertical />\n          </div>\n        ))}\n      </div>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ToastContainer,
      toast,
      Button,
      Space,
      Toast,
      Size,
      ToastCloseButton,
      IconGlobalMessage,
      AlertLink,
      colorSystemError,
      colorSystemSuccess,
      colorSystemWarning,
      FigmaEmbed,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const colorVariantMap = {
          [Toast.Variant.info]: '',
          [Toast.Variant.success]: colorSystemSuccess,
          [Toast.Variant.error]: colorSystemError,
          [Toast.Variant.warning]: colorSystemWarning
        };

        const notify = (toastProps = {}, toastifyOptions = {}) => () => toast(<Toast {...toastProps} mdxType="Toast" />, {
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          ...toastifyOptions
        });

        return <>
        <div>
          {Object.keys(Toast.Variant).map(variant => <div key={variant}>
              <Button color={colorVariantMap[variant]} onClick={notify({
                title: `${variant} toast`,
                description: 'Some description',
                variant,
                icon: IconGlobalMessage,
                actions: ({
                  close
                }) => <AlertLink onClick={close} mdxType="AlertLink">Принять</AlertLink>
              })} mdxType="Button">
                Open {variant} toast
              </Button>
              <Space size={Size.small_2x} vertical mdxType="Space" />
            </div>)}
        </div>
      </>;
      }}
    </Playground>
    <h2 {...{
      "id": "свойства"
    }}>{`Свойства`}</h2>
    <Props of={Toast} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на корневой `}<inlineCode parentName="p">{`div`}</inlineCode>{` компонента `}<inlineCode parentName="p">{`BaseAlert`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      